<template>
  <div class="place">
    <top-header/>
    <div class="panel">
      <div class="panel__left">
        <search-bar @search="search" :placeHolderText="$t('global.search_place')" />
        <div class="panel__content">
          <div class="panel__map">
            <search-map viewOnly :item="place" type="places" @userLocation="onUserLocation"/>
          </div>
          <details-page :item="place" :isCheckIn="isCheckIn" :isSubmit="isSubmit" @checkin="checkin" :checkin-points="awardedPoints" @quizCompleted="quizCompleted" type="place"/>
        </div>
        <bottom-menu :key="bottomMenuReload"/>
      </div>
      <div class="panel__right">
      </div>
    </div>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import SearchMap from '@/components/map/map'
import BottomMenu from '@/components/bottomMenu/bottomMenu'
import SearchBar from '@/components/searchBar/searchBar'
import TopHeader from '@/components/header/header'
import DetailsPage from '@/components/detailsPage/detailsPage'
import { mapGetters } from 'vuex'
import { Places } from '@/api/api'
export default {
  name: 'Place',
  components: { Loading, TopHeader, SearchMap, SearchBar, BottomMenu, DetailsPage },
  data () {
    return {
      isLoading: true,
      loadingStatus: 0,
      awardedPoints: 0,
      place: null,
      isCheckIn: 0,
      isSubmit: 0,
      filters: {},
      bottomMneuReload: 0
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed'
    })
  },
  mounted () {
    this.loadData()
  },
  methods: {
    onUserLocation (data) {
      if (this.place) {
        this.place.isWithinDistance = data.distance <= 200
      }
    },
    async loadData () {
      this.isLoading = true

      const id = this.$route.params?.id

      try {
        const resp = await Places.get(id)

        resp.data.histories.forEach((history) => {
          Object.keys(resp.data.description).forEach((lang) => {
            if (history.title[lang]) {
              resp.data.description[lang] = resp.data.description[lang].replace(
                history.title[lang],
                `<a href="/histories/${history.id}">${history.title[lang]}</a>`
              )
            }
          })
        })

        this.place = resp.data

        const isCheckIn = await Places.isCheckIn(id)

        this.isCheckIn = isCheckIn.data.isCheckIn

        const isSubmit = await Places.isSubmit(id)

        this.isSubmit = isSubmit.data.isSubmit
      } catch (e) {
        this.$toast.error(this.$t('places.load_failed'))
      } finally {
        this.isLoading = false
      }
    },
    async checkin () {
      this.isLoading = true

      const id = this.$route.params?.id

      try {
        const resp = await Places.checkIn(id)

        this.awardedPoints = resp.data.points
        this.$store.dispatch('auth/addPoints', {
          points: resp.data.points
        })
        this.place.checkins.push({})
        this.$toast.success(this.$t('places.checkin_success'))
        this.isCheckIn = 1
      } catch (e) {
        this.$toast.error(this.$t('places.checkin_failed'))
      } finally {
        this.isLoading = false
      }
    },
    search (data) {
      for (const key in data.filters) {
        if (!data.filters[key]) {
          delete data.filters[key]
        }
      }

      if (!data.filters.searchTerm && !data.filters.districts && !data.filters.tagId && !data.filters.forms && !data.filters.nationalEducation) {
        return
      }

      const params = new URLSearchParams(data.filters).toString()
      const url = `/places?${params}`

      this.$router.push(url)
    },
    quizCompleted () {
      this.isSubmit = 1
      this.bottomMenuReload = this.bottomMenuReload + 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "style";
</style>
